body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.datagrid-body>tr>td>a, .datagrid-body>tr>td>span>div>a, .datagrid-body>tr>td>span>a {
  min-width: 0 !important;
}

.select-all {
  color: #fff !important;
}

.simple-form > div > .ra-input {
  display: inline-flex;
  margin-right: 80px;
}

.simple-form > div > .ra-input.ra-input-full-width,
.show-page > div .ra-field.ra-field-full-width {
  width: 100% !important;
}


.simple-form > div > .ra-input.ra-input-full-width > div > h4,
.show-page > div .ra-field.ra-field-full-width h4 {
  margin-top: 1.1765em;
  margin-bottom: 0.35em;
  color: rgba(0, 0, 0, 0.54);
  font-size: 2.125rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.20588em;
}

.ra-input.ra-input-quality_rules {
  width: auto!important;
  height:auto!important;
}

.ra-input.ra-input-input-roles {
  height:auto!important;
}

.ra-input.ra-input-input-users {
  height:auto!important;
}

.ra-input.ra-input-reading_unit_id {
  width: 256px;
}

.ra-input.ra-input-reading_centers {
  margin: 0!important;
}
.ra-input.ra-input-reading_units {
  height:auto!important;
}

.simple-form>div:first-child:after{
        content: ".";
        clear: both;
        display: block;
        visibility: hidden;
        height: 0;
}

.mulitColumnLayout{
  display: flex;
  height: auto;
  width: auto;
}

.mulitColumnLayout>div>div{
  float: left;
  padding-right: 20px;
  min-width: 150px;
  height: 70px;
}

.mulitColumnLayout>div>div.aor-field-{
  clear: both;
  padding-left: 0;
  height: auto;
}
